import React,{useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'

const PlotDetails = () => {
    const links=[{url:'/', label:'Dashboard'},{url:'/layouts',label:'Layouts'}]
    const {id}=useParams()
    const [plotInfo,updatePlotInfo]=useState({})
    const [landBids, updateLandBids]=useState([])
    const [updatedPlotInfo,editPlotInfo]=useState({})
    const [loading, setLoading]=useState(false)
    

    const controller=new AbortController()

    const setEditedPlotInfo=(event)=>{
        editPlotInfo({...updatedPlotInfo,[event.target.name]:event.target.value})
    }

       useEffect(
        ()=>{
            const getPlotInfo=()=>{
                setLoading(true)
                fetch(`${config.BASE_URL}landlayout/plot/${id}`,httpOptions("GET"))
                .then(async response =>{
                    const result=await response.json()
                        updatePlotInfo(result.landInfo)
                        updateLandBids(result.landBids)
                        editPlotInfo(result.landInfo)                
                        setLoading(false)
                })
                .catch(err=>{
                    alert(err)
                    setLoading(false)
                })
            }
            getPlotInfo()
            return ()=>controller.abort()
        },[id]
    )

const submitUpdatedPlotInfo=(event)=>{
    event.preventDefault();
    let dataForPlotUpdate={}
if(window.confirm("Are you sure you want to update the plot details?")){
  
  const reserveAmount=parseInt(updatedPlotInfo.reserveAmount.replace(/,/g,'').replace('NGN',''));
   dataForPlotUpdate={
    "description":updatedPlotInfo.description,
    "location":updatedPlotInfo.location,
    "reserveAmount":reserveAmount,
    "label":updatedPlotInfo.label,
    "width":parseInt(updatedPlotInfo.plotWidth),
    "length":parseInt(updatedPlotInfo.plotLength),
    "bidSeasonDuration":parseInt(updatedPlotInfo.bidSeasonDuration)
  }
}

const tokenBlob = new Blob([JSON.stringify(dataForPlotUpdate,null,2)],{type:"application/json"})

//console.log(dataForPlotUpdate)
setLoading(true)
fetch(config.BASE_URL+`landlayout/plot/update/${id}`,httpOptions("PATCH",tokenBlob))
.then(response =>{
  response.json()
  .then(result =>{
    //console.log(result)
    if(result.status){
      alert("Plot updated successfully")
      updatePlotInfo(result)
      setLoading(false)
    }else{
      alert(result.description)
      setLoading(false)
    }
  })
})
}
   
  return (
     
    <Layout title="Plot Details" links={links}>
        {loading ? <h5 className='text-center'>Loading...</h5> : plotInfo == null ? <h5 className='text-center'>No details found for this plot</h5>:
            <section className="section profile">
                <div className="row">
                    <div className="col-md-8 offset-md-2">

                        <div className="card">
                            <div className="card-body pt-3">
                                <ul className="nav nav-tabs nav-tabs-bordered">

                                <li className="nav-item">
                                    <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Plot Info</button>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#plot-bids">Bids on Plot ({landBids.length})</button>
                                </li>
                                <li className="nav-item">
                                    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Plot Info</button>
                                </li>

                                </ul>
                                <div className="tab-content pt-2">

                                <div className="tab-pane fade profile-overview show active" id="profile-overview">
                                    <h5 className="card-title">About the Plot</h5>
                                    <p className="small fst-italic"></p>
                                    <div className="row">
                                      <div className="col-lg-3 col-md-4 label ">Plot Name </div>
                                      <div className="col-lg-9 col-md-8"> {plotInfo.label}</div>
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 label ">Description </div>
                                    <div className="col-lg-9 col-md-8"> {plotInfo.description}</div>
                                    </div>

                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Bidding Availability</div>
                                    <div className="col-lg-9 col-md-8">{plotInfo.biddingAvailability}</div>
                                    </div>

                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Reserve Amount</div>
                                    <div className="col-lg-9 col-md-8">{plotInfo.reserveAmount}</div>
                                    </div>

                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Location</div>
                                    <div className="col-lg-9 col-md-8">{plotInfo.location}</div>
                                    </div>

                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Bidding Start Date</div>
                                    <div className="col-lg-9 col-md-8">{plotInfo.biddingStartDate || "Not Started"}</div>
                                    </div>

                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Bidding Close Date</div>
                                    <div className="col-lg-9 col-md-8">{plotInfo.biddingClosingDate}</div>
                                    </div>

                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Layout Located</div>
                                    <div className="col-lg-9 col-md-8">{plotInfo.layoutName}</div>
                                    </div>

                                </div>
                                <div className="tab-pane fade" id="plot-bids">
                                    {landBids.length > 0 ? 
                                    <>
                                    <h5 className="card-title">Bids</h5>
                                    <p className="small fst-italic">{landBids.length} bids on {plotInfo.label}</p>
                                    <div className='card'>
                                        <div className='card-body'>
                                        {landBids.map(bid=>{
                                            return <dl className='row' key={bid.bidId}>
                                                <dt className='col-md-2'>Bid Amount</dt><dd className='col-md-3'>{bid.bidAmount}</dd>
                                                <dt className='col-md-2'>Time of Bid</dt><dd className='col-md-3'>{bid.bidTime}</dd>
                                               
                                                <dd className='col-md-2'><Link to={`/users/${bid.bidderId}`}><i className="bi bi-person-square"></i> Bidder</Link></dd>
                                            </dl>
                                        })}
                                        </div>
                                    </div>
                                    </>:<h5 className='text-center'>No bids yet on this plot</h5>
  }
                                </div>
                                <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                    <div className="card">
            <div className="card-body">
              <h5 className="card-title">Edit/Update Plot</h5>

              <form className="row g-3">
                <div className="col-md-12">
                  <div className="form-floating">
                    <input type="text" class="form-control" id="floatingName" placeholder="Name of the plot" value={updatedPlotInfo.label} onChange={setEditedPlotInfo} name="label"/>
                    <label htmlFor="floatingName">Plot Name</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input type="text" class="form-control" id="floatingDescription" placeholder="Description of the plot" value={updatedPlotInfo.description} onChange={setEditedPlotInfo} name="description" />
                    <label htmlFor="floatingDescription">Plot Description</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input type="text" class="form-control" id="floatingReserveAmount" placeholder="Reserve Amount" value={updatedPlotInfo.reserveAmount} onChange={setEditedPlotInfo} name="reserveAmount" />
                    <label htmlFor="floatingReserveAmount">Reserve Amount</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input type="text" class="form-control" id="floatingLocation" placeholder="Location of the plot" value={updatedPlotInfo.location} onChange={setEditedPlotInfo} name="location" />
                    <label htmlFor="floatingLocation">Plot Location</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating">
                    <input type="number" min={0} className="form-control" id="floatingLength" placeholder="Plot Length" value={updatedPlotInfo.plotLength} onChange={setEditedPlotInfo} name="plotLength"/>
                    <label htmlFor="floatingLength">Plot Length </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating">
                    <input type="number" min={0} className="form-control" id="floatingWidth" placeholder="Plot Width" value={updatedPlotInfo.plotWidth} onChange={setEditedPlotInfo} name="plotWidth" />
                    <label htmlFor="floatingWidth">Plot Width</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating">
                    <input type="number" min={1} className="form-control" id="floatingDuration" placeholder="Bid season Duration" value={updatedPlotInfo.bidSeasonDuration} onChange={setEditedPlotInfo} name="bidSeasonDuration" />
                    <label htmlFor="floatingDuration">Bidding Season Duration</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input type="text" class="form-control" id="floatingDate" placeholder="Bidding Start Date" value={updatedPlotInfo.biddingStartDate} />
                    <label htmlFor="floatingDate">Bidding Start Date  </label>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary" onClick={submitUpdatedPlotInfo}>Update</button>
                  <button type="reset" className="btn btn-secondary">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
                                

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>            
        }
    </Layout>
  )
}

export default PlotDetails