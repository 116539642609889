import {useState, useEffect} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'
import { Link } from 'react-router-dom'
//Datatable Modules
import 'datatables.net-bs5'
import $ from 'jquery'; 


const AdminList = () => {
    const links=[{url:'/', label:"Dashboard"},{url:'/admin/new/auth',label:"Add Authorized Admin Email"}]
    const [loading, setLoading]=useState(false)
    const [adminUsers,setAdminUsers]=useState([])

    const controller= new AbortController()
  
    useEffect(()=>{
     const getAllAdminUsers=()=>{
        setLoading(true)
        fetch(`${config.BASE_URL}adminusers`,httpOptions("GET"))
        .then(async response =>{
          if(response.ok){
            const result=await response.json()
              setAdminUsers(result)
              $(function () {
                $('#list-table').DataTable({
                  fixedHeader: true,
                  buttons: [ 'copy', 'csv', 'excel' ]
                });
              })  
              setLoading(false)
            }else{
              alert("System Error! Please retry")
              setLoading(false)
          }
        })
        .catch(err=>{
          alert(err)
          setLoading(false)
        })
     } 
     setLoading(false)
     getAllAdminUsers();
 
     return ()=>controller.abort();
    },[])

    const updateAdminAccountSuspensionStatus=(id, newStatus)=>{

    }

  return (
    <Layout title="Registered Admin Users" links={links}>
      <section>
        {
          loading?<h5 className='text-center'>Loading...</h5>: adminUsers.length < 1 ?<h5 className='text-center'>No admin users found in record</h5>:
          <table className='table table-hover table-striped table-bordered' id='list-table'>
          <thead className='table-dark'>
            <tr>
              <th>Email</th>
              <th>Full Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {adminUsers.map(admin =>{
            return <tr key={admin.id}>
              <td>{admin.email}</td>
              <td>{admin.fullname}</td>
              <td>{admin.accountSuspended?"Suspended":"Active"}</td>
              <td><button className='btn btn-warning' onClick={()=>updateAdminAccountSuspensionStatus(admin.id,admin.accountSuspended)}><i className='bi bi-pencil'></i></button></td>
            </tr>
          })}
          </tbody>
        </table>
        }
      </section>
    </Layout>
  )
}

export default AdminList