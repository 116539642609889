import {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'

const RecordPaymentOffline =()=>{
    const {id}=useParams()
    const [paymentDetails, updatePaymentDetails]=useState()
    const [loading, setLoading]=useState(false)
    const [payData, setPayData]=useState({"comment":'',"payDate":'',"payChannel":'',"paymentId":id,"payCurrency":'',"amountPaid":''})
    const controller= new AbortController()
    const links=[{url:'/',label:'Dashboard'},{url:'/pending/payments',label:'All Pending Payments'}]


    const updatePayData=(e)=>{
        setPayData({...payData,[e.target.name]:e.target.value})
    }

    useEffect(()=>{

        const getPaymentDetails=()=>{
            setLoading(true)
            fetch(`${config.BASE_URL}payments/expected/${id}`,httpOptions('GET'))
            .then(response =>{
                if(response.ok){
               response.json().then(res=>{
                    updatePaymentDetails(res)
               })
                }else{
                    alert("System Error: Unable to get details of expected payment. Please retry")
                }
                setLoading(false)
            })
            .catch(err =>{
                alert(err)
                setLoading(false)
            })
        }

        getPaymentDetails()

        return ()=>controller.abort()
    },[id])
   
    const recordPayment =()=>{
        if(window.confirm("Are you sure you want to record this payment?")){
            setLoading(true)

            const payloadData={
                "payerId": paymentDetails.payerId,
                "channel": payData.payChannel,
                "currency": payData.payCurrency,
                "paymentDate": payData.payDate,
                "amount": payData.amountPaid,
                "comment": payData.comment,
                "recorderId": "",
                "expectedPaymentRecordId": paymentDetails.paymentId
            }

               // console.log(payloadData)
                const payloadBlob=new Blob([JSON.stringify(payloadData,null,2)],{type:"application/json"});

                setLoading(true)
                fetch(`${config.BASE_URL}payments/record`, httpOptions("POST", payloadBlob))
                .then(async response =>{
                    if(response.ok){
                        const result=await response.json();
                        alert(result.description)
                        window.location.href="/payment/record/"+id
                    }else{
                        alert("There was a system malfunction issue. Please retry")
                        console.log(response)
                    }
                }).catch(err=>{
                    alert("There was a system error. Please retry")
                    console.log(err)
                })
           
        }
    }


    return <Layout title="Record Received Payment" links={links}>
            <div className='row'>
                {
                    loading ? <h5>Loading...</h5> :
                    paymentDetails != null ? 
                    <>
                        <div className='col-md-4 offset-md-1'>
                            <dl className='row'>
                                <dt className='col-md-3'>Payer</dt>
                                <dd className='col-md-9'><Link to={`/users/${paymentDetails.payerId}`}>{paymentDetails.payerName}</Link></dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Expected Amount</dt>
                                <dd className='col-md-9'>{paymentDetails.amount}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Purpose</dt>
                                <dd className='col-md-9'>{paymentDetails.purpose}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Payment Code</dt>
                                <dd className='col-md-9'>{paymentDetails.paymentCode}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Current Status</dt>
                                <dd className='col-md-9'>{paymentDetails.status}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Deadline Date</dt>
                                <dd className='col-md-9'>{paymentDetails.deadlineString}</dd>
                            </dl>
                        </div>
                        <div className='col-md-4 offset-md-1'>
                            {
                                paymentDetails.status==='Open'?
                                <form>
                                    <div className='form-group mt-2'>
                                        <label className='control-label'>Date Paid</label>
                                        <input type="date" name="payDate" className='form-control form-control-lg' onChange={updatePayData} />
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label className='control-label'>Select Payment Method</label>
                                        <select name="payChannel" value={payData.payChannel} className='form-control form-control-lg' onChange={updatePayData}>
                                            <option value=''>--Select--</option>
                                            <option value='2'>Online Transfer</option>
                                            <option value='3'>Bank Deposit</option>
                                        </select>
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label className='control-label'>Select Paid Currency</label>
                                        <select name="payCurrency" value={payData.payCurrency} className='form-control form-control-lg' onChange={updatePayData}>
                                            <option value=''>--Select--</option>
                                            <option value='NGN'>NGN</option>
                                            <option value='USD'>USD</option>
                                            <option value='GBP'>GBP</option>
                                        </select>
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label className='control-label'>Amount Paid in Chosen Currency {payData.amountPaid > 0 ? '('+ payData.payCurrency+' '+parseFloat(payData.amountPaid).toLocaleString("en-NG")+')' :''}</label>
                                        <input type="number" min='0' name="amountPaid" className='form-control form-control-lg' onChange={updatePayData} value={payData.amountPaid} />
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label className='control-label'>Comment</label>
                                        <input type="text" name="comment" value={payData.comment} onChange={updatePayData} className="form-control form-control-lg" />
                                    </div>
                                    <div className='form-group mt-2 text-end'>
                                        <button className='btn btn-success btn-lg' onClick={recordPayment} type="button"><i className='bi bi-check'></i> Record</button>
                                    </div>
                                </form>:''
                            }
                        </div> 
                    </> : <h5>No record for this payment</h5>
                }            
            </div>
        </Layout>
}

export default RecordPaymentOffline