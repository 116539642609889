import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import config from '../../config.json'
import Layout from '../shared/Layout'
import { httpOptions } from '../../repository'

const Winlist = () => {
    const links=[{url:'/', label:'Dashboard'},{url:'/bids', label:'List of Bids'}]
    const [winnings, updateWinnings]=useState([])
    const [loading, setLoading]=useState(false)
    const controller= new AbortController()

    useEffect(()=>{
        const getAllWinnings=()=>{
            setLoading(true)
            fetch(`${config.BASE_URL}wins`, httpOptions("GET"))
            .then(async response=>{
                if(response.ok){
                    const result=await response.json()
                    updateWinnings(result)
                    console.log(result)
                    setLoading(false)
                }else{
                    console.log(response)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.log("There was error as follows: "+err)
                setLoading(false)
            })
        }
        getAllWinnings()
        return ()=>controller.abort()
    },[])
  return (
    <Layout title="List of bid Winnings" links={links}>
         <section className='align-items-center'>
            {
                loading ? <h5 className='text-center'>Loading...</h5> : winnings.length === 0 ? <h5 className='text-center'>No wins in record</h5>
                :
                
                 winnings.map(win => {
                    return <div className='row' key={win.winId}>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row d-flex align-items-center'>
                                        <div className='col-sm-1'><strong>Land: </strong><Link to={`/lands-and-layouts/plot/${win.plotId}`}>{win.plotName}</Link></div>
                                        <div className='col-sm-3'><strong>Amount:</strong> {win.bidAmount}</div>
                                        <div className='col-sm-3'><strong>Time of Bid:</strong> {win.bidTime}</div>
                                        <div className='col-sm-3'><strong>Bidder: </strong><Link to={'/users/'+win.bidderId}>{win.bidderName}</Link></div>                         
                                        </div>
                                    </div>
                            </div>
                            <hr />
                        </div>
                      
                        </div>
                })
            }

        </section>
    </Layout>
  )
}

export default Winlist