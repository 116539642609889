import { useLocation, useNavigate } from 'react-router-dom';
import { httpOptions } from '../../repository';
import config from '../../config.json'
import { useEffect } from 'react';

const VerifyEmail = () => {
 const navigate=useNavigate()
    const QueryParameters = new URLSearchParams(useLocation().search);
    const token = QueryParameters.get('token');
    const email = QueryParameters.get('email');
    const controller= new AbortController()

    const verifyWithDetails=()=>{
        if(token == null || email== null)
        {
            alert("Invalid email or token. Unable to proceed with account verification. Please supply a valid email and token to continue");        
        }else{

        fetch(config.BASE_URL +"account/verify?token="+token+"&email="+email, httpOptions('GET'))
        .then(res=>{
            res.json()
            .then(response=>{
                alert(response.description); 
                navigate('/admin-login')               
            })
        }).catch(err=>{
            alert(err)
        })
    }
}
    useEffect(()=>{
        verifyWithDetails()
        return ()=> controller.abort()
    },[token])

    return <div></div>;
};

export default VerifyEmail;
