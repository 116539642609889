import {Link, Navigate} from 'react-router-dom'
import { useState } from 'react'
import config from '../../config.json'
import { isAdminAuthenticated, httpOptions, storeAuthToken } from '../../repository'


const Register = () => {

    const [userDetails, updateDetails]=useState({
            "email":"",
            "password":"",
            "confirmPassword":"",
            "surname":"",
            "othernames":""
        })

    const handleChange=(e)=>{
        updateDetails({...userDetails,[e.target.name]:e.target.value})
    }

    const submitRegForm=(e)=>{
        e.preventDefault();
        const tokenBlob= new Blob([JSON.stringify(userDetails, null, 2)], { type: 'application/json' });

        fetch(config.BASE_URL+'account/adminregister', httpOptions("POST",tokenBlob))
        .then(res=>{
            res.json()
            .then(token => {
                if(token.status){
                    storeAuthToken(token)
                    window.location.href="/"
                }else{
                    alert(token.description)
                }
            })
        })
        .catch(err=>{
            alert(err);
        })

    }

    return isAdminAuthenticated() ? <Navigate to="/" replace={true} /> : (
        <main>
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            
              <div className="d-flex justify-content-center pt-4">  <img src="/assets/images/logo.png" style={{width:'150px'}} alt="ePABS logo" className='img-fluid' />
              </div>
              <div className='py-2'>
                <span className="d-none d-md-block">Register as Admin</span>
            </div>    
              {/* <!-- End Logo --> */}

              <div className="card mb-3">

                <div className="card-body">

                  <form className="row g-3 needs-validation" noValidate onSubmit={submitRegForm}>
                    <div className="col-12">
                      <label htmlFor="surname" class="form-label">Surname</label>
                      <input type="text" name="surname" className="form-control" id="surname" required value={userDetails.surname} onChange={handleChange} />
                      <div className="invalid-feedback">Please, enter your surname!</div>
                    </div>
                    <div class="col-12">
                      <label htmlFor="otherNames" className="form-label">Other Names</label>
                      <input type="text" name="othernames" className="form-control" id="otherNames" required value={userDetails.othernames} onChange={handleChange} />
                      <div className="invalid-feedback">Please, enter your other name!</div>
                    </div>                

                    <div className="col-12">
                      <label htmlFor="email" className="form-label">Email</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="email" name="email" className="form-control" id="email" required value={userDetails.email} onChange={handleChange} />
                        <div className="invalid-feedback">Please enter your email.</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="password" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control" id="password" required value={userDetails.password} onChange={handleChange} />
                    </div>

                    <div className="col-12">
                      <label htmlFor="passwordConfirmation" className="form-label">Confirm Password</label>
                      <input type="password" name="confirmPassword" className="form-control" id="passwordConfirmation" required value={userDetails.confirmPassword} onChange={handleChange} />
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit">Register</button>
                    </div>
                    <div className="col-12">
                      <p className="small mb-0">Already registered as an admin? <Link to="/admin-login">Log in</Link></p>
                    </div>
                  </form>

                </div>
              </div>
            </div>
        </div>
        </div></section></main>
    )
}

export default Register
