import {useState, useEffect} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'

const GrantedAdminEmailList = () => {
  const links=[{url:'/', label:"Dashboard"},{url:'/admin/new/auth',label:"Add Authorized Admin Email"}]
  const [loading, setLoading]=useState(false)
  const [items, updateItems]=useState([]);

  const controller = new AbortController()

  useEffect(()=>{
    const getAuthEmails=()=>{
      setLoading(true)
      fetch(`${config.BASE_URL}adminUsers/authorizedemails`,httpOptions("GET"))
      .then(async response =>{
        if(response.ok){
          const result=await response.json()
          updateItems(result)
          setLoading(false)
        }else{
          alert("System Error! Please retry")
          setLoading(false)
        }
      })
      .catch(err=>{
        alert(err)
        setLoading(false)
      })
    }
    getAuthEmails()
    return ()=>controller.abort()
  },[])
 

  return (
    <Layout title="Authorizations for Admin Registration" links={links}>
        <section>
          {loading?<h5 className='text-center'>Loading...</h5>:items.length < 1?<h5 className='text-center'>No authorized  emails found in record</h5>:
          <table className='table table-striped table-hover table-bordered'>
            <thead className='table-dark'>
              <tr>
                <th>Email</th><th>Role</th><th>Time Added</th><th>Added By</th><th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item =>{
                return <tr key={item.id}>
                  <td>{item.emailAddress}</td>
                  <td>{item.roleName}</td>
                  <td>{item.timeAdded}</td>
                  <td>{item.addedBy}</td>
                  <td><button className='btn btn-danger'><i class="bi bi-trash-fill"></i> Remove</button></td>
                </tr>
              })}
            </tbody>
          </table>
          }
        </section>
    </Layout>
  )
}

export default GrantedAdminEmailList