import React,{useState,useEffect} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'
import { Link } from 'react-router-dom'

const LayoutList = () => {
    const links=[{url:'/', label:'Dashboard'}]
    const [loading, setLoading]=useState(false)
    const [allLayouts, updateLayouts]= useState([])
    const controller= new AbortController()


    const getLayouts=()=>{
        setLoading(true)
        fetch(config.BASE_URL+'landlayout',httpOptions('GET'))
        .then(response =>{
            response.json()
            .then(result =>{
                updateLayouts(result)
                setLoading(false)
                console.log(result)
            })
        }).catch(err =>{
            alert(err)
            setLoading(false)
        })
    }

    useEffect(()=>{     
        getLayouts()
        return ()=>controller.abort()
    },[])
  return (
    <Layout title="Land Layouts" links={links}>
        <section className=''>
            <div className='row d-flex align-items-center justify-content-center'>
                {loading ? <h5 className='text-center'>Loading...</h5> : allLayouts.length === 0 ? <h5 className='text-center'>No layout found in records</h5> : allLayouts.map(layout =>{
                    return <dl className='row' key={layout.layoutId}>
                        <dt className='col-sm-1'>Title</dt>
                        <dd className='col-sm-2 text-start'>{layout.title}</dd>
                        <dt className='col-sm-1'>Description</dt>
                        <dd className='col-sm-2 text-start'>{layout.description}</dd>
                        <dt className='col-sm-1'>Description</dt>
                        <dd className='col-sm-2 text-start'>{layout.description}</dd>
                        <dt className='col-sm-1'>Dimensions</dt>
                        <dd className='col-sm-2 text-start'>{layout.dimensions}</dd>
                        <dt className='col-sm-2'>Plots</dt>
                        <dd className='col-sm-10 text-start'>{layout.plots.length > 0 ?
                        
                        <div className="accordion bg-light" id="plotsList">
  <div className="accordion-item">
    <h2 className="accordion-header" id="plotsCount">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlots" aria-expanded="true" aria-controls="collapseOne">
        {layout.plots.length}
      </button>
    </h2>
    <div id="collapsePlots" className="accordion-collapse collapse" aria-labelledby="plotsCount" data-bs-parent="#plotsList">
      <div className="accordion-body">
          <h5> Plots on {layout.title}</h5>
        <ul className='list-group'>
              {layout.plots.map(plot =>{
                  return <li key={plot.landId} className="list-group-item d-flex justify-content-between"><Link to={`/layouts/plot/${plot.landId}`} title='View plot details'>{plot.landLabel}</Link> <span>Reserve Amount: <strong>{plot.reserveAmount}</strong></span></li>
              })}              
        </ul>
      </div>
    </div>
  </div>
</div>
                        
                        : layout.plot.length} 
                        </dd>                        
                    </dl>
                })}
            </div>
        </section>
    </Layout>
  )
}

export default LayoutList