import config from './config.json'

    export function adminLogin(data) {

      const tokenBlob = new Blob([JSON.stringify({ email:data.email,password:data.password }, null, 2)], { type: 'application/json' });
      const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
        }
      const loginPath=config.BASE_URL+'account/login';
      fetch(loginPath, options)
      .then(async r => {
        return await r.json()
          .then(accessToken => {
            if(!accessToken.status){
              alert(accessToken.description)
            }else{
              storeAuthToken(accessToken);
              window.location.href='/'
            } 
        });
    })      
    .catch(e=>{
        alert('Sorry, Error Occured: '+JSON.stringify(e));
    })
    }

    export const ensureAuthenticated=()=>{
      if(!isAdminAuthenticated()){
        window.location.href='/admin-login';
      }
    }

    export const httpOptions =(method,body=false)=> {     
      return body?{
      method: method,
      body:body,
      mode: 'cors',  
      accepts: "application/json",         
      cache: 'default',
      contentType : "application/json",
      headers:{
          "Authorization":"Bearer "+ localStorage.getItem('adminAccessToken')
        }
      }:{
        method: method,
        mode: 'cors',  
        accepts: "application/json",         
        cache: 'default',
        contentType : "application/json",
        headers:{
            "Authorization":"Bearer "+ localStorage.getItem('adminAccessToken')
          }
        }
    }

    export function isAdminAuthenticated(){        
        if(localStorage.getItem('adminAccessTokenExpiration') !==null 
        && localStorage.getItem('adminAccessTokenExpiration') !=='' 
        && new Date() < new Date(localStorage.getItem('adminAccessTokenExpiration'))
        ){
          return true;
        }

        clearAuthTokens();
        
        return false;
 }

  const clearAuthTokens=()=>{
    localStorage.getItem("adminAccessToken") != null && localStorage.removeItem("adminAccessToken");
        localStorage.getItem("adminAccessTokenExpiration") != null && localStorage.removeItem("adminAccessTokenExpiration")
        localStorage.getItem('admin') != null && localStorage.removeItem('admin')
        localStorage.getItem('role') != null && localStorage.removeItem('role')
        return;
  }

 export const logout= () =>{
   
    if(isAdminAuthenticated()){  
        const bearer = 'Bearer ' + localStorage.getItem('adminAccessToken');  
        const options = {
            method: 'POST',
            mode: 'cors',  
            accepts: "application/json",         
            cache: 'default',
            contentType : "application/json",
            headers:{
                'Authorization':bearer,
                'Content-Type': 'application/json'
            }
        }

        fetch(config.BASE_URL+'account/logout',options)
        .then(response => {
          console.log(response)
          if(!response.ok){
            clearAuthTokens();
            window.location.href='/admin-login';
          }
            return response.json()
          })
          .then(result => {
            if(result.status){
              clearAuthTokens();
                alert(result.description)
                window.location.href='/admin-login';
            }
          })
          .catch((err) => {
            alert(err)
          })
    }else{
        window.location.href='/admin-login';
    }
}

   

    export const onFailure = (error) => {
        console.log(error);
        alert("Error occured and has been logged in the console")
      };

 
      export const storeAuthToken=(tokenString) =>
      {
          localStorage.setItem('adminAccessToken',tokenString.token);
          localStorage.setItem('adminAccessTokenExpiration', new Date(tokenString.expiration));
          localStorage.setItem('role',tokenString.roleName);
          localStorage.setItem('admin',tokenString.name);
          return;
      }