import { useState, useEffect } from "react";
import Layout from "../shared/Layout";
import { Link, Navigate } from "react-router-dom";
import config from '../../config.json'
import { httpOptions, isAdminAuthenticated } from "../../repository"
import 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-fixedheader-bs5'
import $ from 'jquery'; 


const UsersList = () => {
const [loading, setLoading]=useState(false)
const [userList, updateUserList]=useState([])
const links=[{url:'/', label:'Dashboard'}]
const controller=new AbortController();

const GetAllUsers=()=>{
    setLoading(true);
    fetch(config.BASE_URL+"users",httpOptions('GET'))
    .then(response=>{
      // console.log(response)
        response.json()
        .then(res=>{
            updateUserList(res)
            $(function () {
              $('#list-table').DataTable({
                  fixedHeader: true,
                  buttons: ['copy', 'csv', 'excel']
              });
            })  
      setLoading(false)
        })
    })
    .catch(err=>{
        alert(err)
    })
}

useEffect(
    ()=>{
        GetAllUsers();
        return ()=>controller.abort()
    },[]
)


  return !isAdminAuthenticated()? <Navigate to='/admin-login' />:<Layout title="Registered Users" links={links}>
        { 
            loading ? <h4 className='text-center'>Loading...</h4>:(userList.length === 0) ? <h4 className='text-center'> No users found </h4> :
            
          <div className="card">
          <div className="card-body">
            <h5 className="card-title">List of Registered Users</h5>            
            <table className="table datatable table-hover table-striped table-bordered" id="list-table">
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Full Details</th>
                </tr>
              </thead>
              <tbody>
                {userList.map(u =>{
                  const detailsLink="/users/"+u.userId;
                  return <tr key={u.userId}>
                    <td>{u.emailAddress}</td>
                    <td>{u.fullname}</td>
                    <td>{u.phoneNumber}</td>
                    <td className="text-center"><Link to={detailsLink}><button className="btn btn-success"><i className="bi bi-eye"></i></button></Link></td>
                  </tr>
                })}               
                </tbody>
              </table>
            </div>
          </div>
        }
  </Layout>
}

export default UsersList;
