import {useState,useEffect} from 'react'
import Layout from '../shared/Layout'
import { Link } from 'react-router-dom'
import config from '../../config.json'
import { httpOptions } from '../../repository'

const Bidders = () => {
    const links=[{url:'/', label:"Dashboard"},{url:'/users',label:'All Users'},{url:'/bids',label:'All Bids'}]

    const [allBidders, updateAllBidders]=useState([])
    const [loading,setLoading]=useState(false)

    const controller=new AbortController();

    useEffect(()=>{
        const getAllBidders=()=>{
            setLoading(true)
            fetch(`${config.BASE_URL}bidders`,httpOptions("GET"))
            .then(async response =>{
                const result=await response.json()
                updateAllBidders(result)
                setLoading(false)
            })
            .catch(err=>{
                alert(err)
                setLoading(false)
            })
            setLoading(false)
        }
       
        getAllBidders()
        return ()=>controller.abort();
    },[])

  return (
    <Layout title='Bidders' links={links}>
         { 
            loading ? <h4 className='text-center'>Loading...</h4>:(allBidders.length === 0) ? <h4 className='text-center'> No users found </h4> :
            
          <div className="card">
          <div className="card-body">
            <h5 className="card-title">All Bidders</h5>            
            <table className="table datatable table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">No. of Bids</th>
                </tr>
              </thead>
              <tbody>
                {allBidders.map(u =>{
                  return <tr key={u.userId}>
                    <td><Link to={`/users/${u.bidderId}`}>{u.bidderName}</Link></td>
                    <td><Link to={`/user/bids/${u.bidderId}`}>{u.bids}</Link></td>
                   
                  </tr>
                })}               
                </tbody>
              </table>
            </div>
          </div>
        }
    </Layout>
  )
}

export default Bidders