import { Form,Button } from 'react-bootstrap'
import { useState } from 'react'
import config from '../../config.json';
import { useParams } from 'react-router-dom';
import { httpOptions } from '../../repository';

const NewExpectedPaymentModal = () => {

    // const history=useHistory();
    const {userId}=useParams()
    let [expectedAmount, setExpectedAmount] = useState(0)
    let [paymentDeadline, setPaymentDeadline]=useState('');
    let [paymentPurpose, setPaymentPurpose]=useState('');
	const [loading, setLoading]=useState(false)

    let amountString=expectedAmount > 0 ?`(NGN ${parseInt(expectedAmount).toLocaleString("en-NG")})` :''

    const submitNewExpectedPayment=(e)=>{
        e.preventDefault();
		setLoading(true)
        const expectedPaymentData={"expectedAmount":parseFloat(expectedAmount),"payerId":userId, paymentDeadline,paymentPurpose};
        const tokenBlob= new Blob([JSON.stringify(expectedPaymentData, null, 2)], { type: 'application/json' });        

        fetch(config.BASE_URL+'payments/pending/add', httpOptions("POST",tokenBlob))
        .then(async res => {
           
            const msg = await res.json();
            if (msg.status){
                alert(msg.description);
                window.location.href=`/users/${userId}`
            } else {
                alert(msg.description);
            }        
      })
        .catch(err => {
          alert(err)
          console.log(err)
		  setLoading(false)
          });
        }


    return (
		<>
			{
				loading ? <h5>Loading...</h5> :
				<button type="button" class="btn btn-outline-dark btn-lg" data-bs-toggle="modal" data-bs-target="#expectedPaymentFormModal"><i className='bi bi-cash'></i> Add Expected Payment
				</button>
			}
			<div className="modal fade" id="expectedPaymentFormModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="expectedPaymentFormModalLabel" aria-hidden="true">
		  <div className="modal-dialog modal-dialog-centered">
			<div className="modal-content">
			  <div className="modal-header">
				<h5 className="modal-title" id="expectedPaymentFormModalLabel">New Expected Payment</h5>
				<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <Form onSubmit={submitNewExpectedPayment} >
				<Form.Group className="mb-3" controlId="">				   
					<Form.Label>Expected Amount {amountString} </Form.Label>
					<Form.Control type="number" min="0" placeholder="Enter expected amount" title='Expected Amount' onChange={e => setExpectedAmount(e.target.value)} />
				</Form.Group>
        <Form.Group className="mb-3" controlId="">				   
					<Form.Label>Payment Purpose </Form.Label>
					<Form.Control type="text"  placeholder="Enter purpose of payment" title='Purpose' onChange={e => setPaymentPurpose(e.target.value)} />
				</Form.Group>
        <Form.Group className="mb-3" controlId="">				   
					<Form.Label>Payment Deadline </Form.Label>
					<Form.Control type="date"  placeholder="Enter last date for payment" title='Payment deadline' onChange={e => setPaymentDeadline(e.target.value)} />
				</Form.Group>
				<div className="row d-flex justify-content-between">
					<div className="col-sm-6 text-left">
					<Button variant="success" type="submit" >
						Create Expectation
					</Button>
					</div>                               
				</div>
			</Form>
			  </div>
			  <div className="modal-footer">
				<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
			  </div>
			</div>
		  </div>
		</div>
		</>
    )
}

export default NewExpectedPaymentModal
