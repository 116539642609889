import Topbar from './Topbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import Breadcrumbs from './Breadcrumbs'
import { isAdminAuthenticated } from '../../repository'
import { Navigate } from 'react-router-dom'

const Layout = (props) => {
    return (!isAdminAuthenticated())?<Navigate to='/admin-login'></Navigate>:<>
      <Topbar />
      <Sidebar />
      <main id="main" className="main">
      <Breadcrumbs title={props.title} links={props.links} />
        <section className="section">
          {props.children}
        </section>
      </main>      
      <Footer />
    </>
}

export default Layout
