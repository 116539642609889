import Layout from './shared/Layout'
import { useEffect, useState } from 'react'
import {Navigate, Link} from 'react-router-dom'
import {isAdminAuthenticated} from '../repository'
import config from '../config.json'
import { httpOptions } from '../repository'

const Dashboard = () => {
    const [dashboardData, setDashboardData]=useState({biddersCount:0,bidsCount:0,winsCount:0,highestBidAmount:0,totalBidAmount:0})
    const [loading, setLoading]=useState(false)
    const controller= new AbortController();

    //const links=[{url:"/","label":"First Link"}]
    useEffect(()=>{
        const getSummaries=()=>{
            setLoading(true)
            fetch(`${config.BASE_URL}dashboard/summaries`,httpOptions("GET"))
            .then(async response =>{
                const result = await response.json()
                // console.log(result)
                setDashboardData(result)
                setLoading(false)
            })
            .catch(err=>{
                alert(err)
            })
        }

        getSummaries()
        return ()=>controller.abort()
    },[])
    return (
        !isAdminAuthenticated()? <Navigate to="admin-login" replace={true} /> :
        <>
            <Layout title="Dashboard">
            <div className="row">
                <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Registered Users <span> | All users on the platform </span></h5>
                    <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{loading?"Loading...":<Link to='/users'>{dashboardData.allUsers}</Link>}</h6>
                      {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                    </div>
                  </div>
                    </div>
                </div>
                </div>
                <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Total Bids <span> | All the bids made </span></h5>
                    <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-hand-index-thumb"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{loading?"Loading...":<Link to='/bids'>{dashboardData.bidsCount}</Link>}</h6>
                      
                    </div>
                  </div>
                    </div>
                </div>
                </div>
             
                <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">All Bidders <span> | All bidding users </span></h5>
                    <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-person-lines-fill"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{loading?"Loading...":<Link to='/bidders'>{dashboardData.biddersCount}</Link>}</h6>                     
                    </div>
                  </div>
                    </div>
                </div>
                </div>
                <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Total Wins <span> | All the winings </span></h5>
                    <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-trophy"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{loading?"Loading...":<Link to='/wins'>{dashboardData.winsCount}</Link>}</h6>
                    </div>
                  </div>
                    </div>
                </div>
                </div>
                <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Highest Bid <span> | Highest bid recorded </span></h5>
                    <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-bar-chart-steps"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{loading?"Loading...":dashboardData.highestBidAmount}</h6>
                    </div>
                  </div>
                    </div>
                </div>
                </div>
                <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Total Bid Amount <span> | Sum of all bid Amounts </span></h5>
                    <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-cash"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{loading?"Loading...":dashboardData.totalBidAmount}</h6>
                    </div>
                  </div>
                    </div>
                </div>
                </div>
            </div>
            </Layout>
        </>
    )
}

export default Dashboard
