import {NavLink} from 'react-router-dom'
import { isAdminAuthenticated } from '../../repository'

const Sidebar = () => {
    return (
        <aside id="sidebar" className="sidebar">
          {isAdminAuthenticated() ?
        <ul className="sidebar-nav" id="sidebar-nav">
    
          <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/">
            <i class="bi bi-speedometer2"></i>          <span>Dashboard</span>
            </NavLink>
          </li>
         
          <li className="nav-item">
            <NavLink className="nav-link collapsed" to='/users'>
              <i className="bi bi-people"></i>
              <span>Users</span>
            </NavLink>
          </li>
        
    
          <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/layouts">
            <i className="bi bi-pin-map-fill"></i>
              <span>Layouts</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/bids">
            <i className="bi bi-hand-index-thumb"></i>
              <span>Bids</span>
            </NavLink>
          </li>
          {/* <!-- End Register Page Nav --> */}
    
          <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/wins">
              <i className="bi bi-trophy"></i>
              <span>Wins</span>
            </NavLink>
          </li>
          {/* <!-- End Login Page Nav --> */}
    
          <li className="nav-item">
        <a className="nav-link collapsed" data-bs-target="#payments-nav" data-bs-toggle="collapse" href="#">
          <i className="bi bi-credit-card"></i><span>Payment</span><i className="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul id="payments-nav" className="nav-content collapse">
          <li>
            <NavLink to="/payments">
              <i className="bi bi-circle"></i><span>Successful Payments</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/pending/payments">
              <i className="bi bi-circle"></i><span>Pending Payments</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/failed/payments">
              <i className="bi bi-circle"></i><span>Failed Payments</span>
            </NavLink>
          </li>                                    
        </ul>
      </li>
      <li className="nav-item">
        <a className="nav-link collapsed" data-bs-target="#admin-nav" data-bs-toggle="collapse" href="#">
        <i class="bi bi-person-workspace"></i><span>Admin</span><i className="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul id="admin-nav" className="nav-content collapse">
          <li>
            <NavLink to="/admins">
              <i className="bi bi-circle"></i><span>Admins</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/authorizedemails">
              <i className="bi bi-circle"></i><span>Authorized Admin Emails</span>
            </NavLink>
          </li><li>
            <NavLink to="/admin/new/auth">
              <i className="bi bi-circle"></i><span>Add New Email</span>
            </NavLink>
          </li>
                                       
        </ul>
      </li>
          {/* <!-- End Error 404 Page Nav --> */}
    
          <li className="nav-item">
            <NavLink className="nav-link " to="/messages">
              <i className="bi bi-chat"></i>
              <span>Messages</span>
            </NavLink>
          </li>
          {/* <!-- End Blank Page Nav --> */}
    
        </ul>
        :'' }
      </aside>
    )
}

export default Sidebar
