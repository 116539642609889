import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Winlist from './components/wins/Winlist';
import PageNotFound from './components/shared/PageNotFound'
import Dashboard from './components/Dashboard'
import Register from './components/account/Register';
import Login from './components/account/Login';
import VerifyEmail from './components/account/VerifyEmail';
import UsersList from './components/user/UsersList';
import UserDetails from './components/user/UserDetails';
import UserBids from './components/user/UserBids';
import PaymentListSuccessfull from './components/payments/PaymentListSuccessfull';
import PaymentListPending from './components/payments/PaymentListPending';
import PaymentListFailed from './components/payments/PaymentListFailed';
import BidList from './components/bids/BidList';
import LayoutList from './components/LandsAndLayouts/LayoutList';
import PlotDetails from './components/LandsAndLayouts/PlotDetails';
import RecordPaymentOffline from './components/payments/RecordPaymentOffline';

import ExpectedPayments from './components/payments/ExpectedPayments';
import ExpectedPaymentUpdate from './components/payments/ExpectedPaymentUpdate';
import Bidders from './components/user/Bidders';
import AdminList from './components/admin/AdminList';
import GrantedAdminEmailList from './components/admin/GrantedAdminEmailList';
import GrantEmailAuth from './components/admin/GrantEmailAuth';
import MessageList from './components/GuestMessages/MessageList';
import MessageDetails from './components/GuestMessages/MessageDetails';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Dashboard />}></Route>
        <Route path="/admin-login" element={<Login />}></Route>
        <Route path="/admin-register" element={<Register />}></Route>
        <Route path="/account/verifyemail" element={<VerifyEmail />}></Route> 
        <Route path="/users" element={<UsersList />}></Route>
        <Route path="/users/:userId" element={<UserDetails />}></Route>
        <Route path='/user/bids/:id'element={<UserBids />}></Route>
        <Route path='/payments' element={<PaymentListSuccessfull />}></Route>
        <Route path='/pending/payments' element={<PaymentListPending />}></Route>
        <Route path='/failed/payments' element={<PaymentListFailed />}></Route>
        <Route path='/payment/record/:id' element={<RecordPaymentOffline />}></Route>
        <Route path='/expected/payment/update/:id' element={<ExpectedPaymentUpdate/>} />
        
        <Route path='expected/payments' element={<ExpectedPayments />}></Route>
        <Route path='/bids' element={<BidList />}></Route>
        <Route path='/wins' element={<Winlist />}></Route>

        <Route path="layouts" element={<LayoutList />}></Route>
        <Route path="/layouts/plot/:id" element={<PlotDetails />}></Route>
        <Route path='/bidders' element={<Bidders />}></Route>
        <Route path='*' element={<PageNotFound />}></Route>
        <Route path='/admins' element={<AdminList />}></Route>
        <Route path='/admin/authorizedemails' element={<GrantedAdminEmailList />}></Route>
        <Route path='/admin/new/auth' element={<GrantEmailAuth />}></Route>
        <Route path="/messages" element={<MessageList />}></Route>
        <Route path="/messages/:id" element={<MessageDetails />}></Route>
      </Routes>  
    </Router> 
  )
}

export default App;