import { useState, useEffect } from 'react'
import {Link, useNavigate, Navigate} from 'react-router-dom'
import { isAdminAuthenticated, httpOptions, storeAuthToken } from '../../repository'
import config from '../../config.json'
import Dashboard from '../Dashboard'

const Login = () => {
  const navigate=useNavigate()

const [credentials, setCredentials]=useState({email:'', password:''})
const [loading, setLoading]=useState(false)

  const submitLoginForm=(e)=>{
    e.preventDefault();
   tryLogin()  
  }

  const tryLogin=()=>{
    const tokenBlob=new Blob([JSON.stringify(credentials,null,2)],{type:"application/json"});
    setLoading(true)
    fetch(config.BASE_URL+"account/login",httpOptions("POST",tokenBlob))
    .then(res=>{
      res.json()
      .then(token=>{
        if(!token.status){
          alert(token.description)
          setLoading(false)
          return;
        }else{
          storeAuthToken(token);
          navigate('/')
        }
      })
    })
    .catch(err=>{
      alert(err)
    })
  }

  const updateCredentials=(e)=>{
    setCredentials({...credentials,[e.target.name]:e.target.value})
  }

    return isAdminAuthenticated() ? <Navigate to="/" replace={true} /> : (
        <main>
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            
              <div className="d-flex justify-content-center pt-4">  <img src="/assets/images/logo.png" style={{width:'150px'}} alt="ePABS logo" className='img-fluid' />
              </div>
              <div className='py-2'>
                <span className="d-none d-md-block">Admin Login</span>
            </div>    
              {/* <!-- End Logo --> */}

              <div className="card mb-3">

                <div className="card-body">
                {loading ? <h5 className='text-center'>Loading...</h5> :
                <form className="row g-3 needs-validation" novalidate onSubmit={submitLoginForm}>

                    <div className="col-12 pt-3">
                      <label for="yourUsername" className="form-label">Username</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="text" name="email" className="form-control" id="yourEmail" required value={credentials.email} onChange={updateCredentials} />
                        <div className="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label for="yourPassword" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control" id="yourPassword" required value={credentials.password} onChange={updateCredentials} />
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>                  
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit">Login as Admin</button>
                    </div>
                    <div className="col-12">
                      <p className="small mb-0">Not Registered as Admin? <Link to="/admin-register">Register now</Link></p>
                    </div>
                  </form>
                }
                </div>
              </div>
                
            </div>
          </div>
        </div>
      </section>
  </main>  
    )
}

export default Login
