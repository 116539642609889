import {useParams, Link, Navigate} from 'react-router-dom';
import config from '../../config.json'
import { useEffect, useState } from 'react';
import { httpOptions, isAdminAuthenticated } from '../../repository';
import Layout from '../shared/Layout';
import NewExpectedPaymentModal from '../payments/NewExpectedPaymentModal';

const UserDetails = () => {
    const {userId}=useParams()
    const controller=new AbortController()
    const links=[{url:'/', label:'Dashboard'},{url:'/users/',label:"User List"}]
    const [userDetails, updateDetails]=useState({
        "surname":'',
        "othernames":'',
        "phone":'',
        'email':'',
        "bids":'',
        'socialMediaHandles':[],
        'last_login_time':'',
        'last_logout_time':''
    })

    const [pendingPayments, setPendingPayments]=useState([])
    const [pendingPaymentsLoading,setPendingPaymentsLoading]=useState(false)

    const [loading, setLoading]=useState(false)


    const getUserPendingPayments=()=>{
      setPendingPaymentsLoading(true)
      fetch(config.BASE_URL+'payments/pending/'+userId, httpOptions('GET'))
      .then(async response =>{
        const res=await response.json();
        setPendingPayments(res);
        setPendingPaymentsLoading(false)
      })
      .catch(err =>{
        alert(err)
        .console.log(err)
      })
    }

    const fetchUserDetails=()=>{
        setLoading(true);
        fetch(config.BASE_URL+"users/"+userId, httpOptions("GET"))
        .then(res=>{
            res.json()
            .then(r => {
                if(!r.status){
                    alert(r.description)
                    setLoading(false)
                }else{
                    updateUserDetails(r)
                    setLoading(false)
                }
            })
        })
        .catch(err=>{
            alert(err)
        })
    }

const updateUserDetails=(info)=>{
    updateDetails({...userDetails,
        "surname":info.surname,
        "othernames":info.othernames,
        "phone":info.phone,
        'email':info.email,
        'userId':info.userId,
        "bids":info.bids,
        "profiled":info.profilingStatus,
        "verified":info.verificationStatus,
        'last_login_time':info.lastLoggedIn,
        'last_logout_time':info.lastLoggedOut,
        "socialMediaHandles":info.socialMediaHandles
    })

    //console.log(info)
}

useEffect(
    ()=>{
        fetchUserDetails()
        getUserPendingPayments()
        return ()=>controller.abort()
    },[]
)

const blockPendingPayment=(id)=>{
  const blockWarning="Click 'Ok' to irreversibly block this payment. Please note that nobody will be able to make this payment if you block it";
  if(window.confirm(blockWarning)){
    setLoading(true)
    fetch(`${config.BASE_URL}payments/pending/blockpayment/${id}`, httpOptions("PATCH",{paymentId:id}))
    .then(async response=>{
      if(response.ok){
        
        const result=await response.json();
        alert(result.description)
        window.location.reload()
        //setLoading(false)
      }else{
        alert("System error. Please retry")
        setLoading(false)
      }
    })
    .catch(err=>{
      alert(err)
    })
  }
}

const markAsProfiled=()=>{
  
  if(window.confirm("Are you sure you want to mark this user as profiled?")){
    const tokenBlob=new Blob([JSON.stringify({userId,"profiling":true})],{type:"application/json"})
    fetch(config.BASE_URL+'users/updateprofile',httpOptions("POST",tokenBlob))
    .then(response =>{
      response.json()
      .then(res => {
        if(res){
          alert("User successfully profiled")
          fetchUserDetails()
        }else{
          alert("System error in profiling user")
          setLoading(false)
        }
      })
    }).catch(err=>{
      alert(err)
      console.log(err)
    })
  }
}

const markAsVerified=()=>{
  
  if(window.confirm("Are you sure you want to mark this user as verified?")){
    setLoading(true)
    const tokenBlob= new Blob([JSON.stringify({"userId":userId,"verification":true},null,2)],{type:"application/json"})
   
    fetch(config.BASE_URL+"users/updateprofile",httpOptions("POST",tokenBlob))
    .then(response =>{
      console.log(response)
      response.json()
      .then(res => {
        if(res){
          alert("User verification successful")
          setLoading(false)
          fetchUserDetails();
        }else{
          alert("Sorry, there was a problem verifying use at this time. Please  retry")

          setLoading(false)
        }
      })
    })
    .catch(err=>{
      alert(err)
    })
  }
}

  return <Layout title="User Details" links={links}>
      {loading ? <h5>Loading...</h5> :
          <section className="section profile">
          <div className="row">
            <div className="col-lg-4">    
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">    
                  {/* <img src="/assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                  <h2> {userDetails.surname}</h2>
                  <h3> {userDetails.othernames}</h3>
                  <div className="social-links mt-2">
                    <a href={`https://${userDetails.socialMediaHandles[1]}`} className="twitter"><i className="bi bi-twitter"></i></a>
                    <a href={`https://${userDetails.socialMediaHandles[0]}`} className="facebook"><i className="bi bi-facebook"></i></a>                    
                  </div>
                </div>
              </div>    
            </div>
    
            <div className="col-lg-8">
    
              <div className="card">
                <div className="card-body pt-3">
                  {/* <!-- Bordered Tabs --> */}
                  
                  <div className="tab-content pt-2">
    
                    <div className="tab-pane fade show active profile-overview" id="profile-overview">
                         <h5 className="card-title">User Details</h5>    
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label ">Surname</div>
                        <div className="col-lg-9 col-md-8">{userDetails.surname}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label ">Other Names</div>
                        <div className="col-lg-9 col-md-8">{userDetails.othernames}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Phone</div>
                        <div className="col-lg-9 col-md-8">{userDetails.phone}</div>
                      </div>
    
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Email</div>
                        <div className="col-lg-9 col-md-8">{userDetails.email}</div>
                      </div>
    
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Bids</div>
                        <div className="col-lg-9 col-md-8"><Link to={`/user/bids/${userDetails.userId}`}>
                        {userDetails.bids}</Link></div>
                      </div>
    
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Verified</div>
                        <div className="col-lg-9 col-md-8 d-flex justify-content-between">{userDetails.verified} {userDetails.verified ==="Not yet verified"?<button className='btn btn-primary' onClick={markAsVerified}><i class="bi bi-check"></i> Mark as Verified</button>:<i class="bi bi-bookmark-check-fill"></i>}</div>
                      </div>
    
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Profiled</div>
                        <div className="col-lg-9 col-md-8 d-flex justify-content-between">{userDetails.profiled }{userDetails.profiled ==="Not yet profiled" ? <button className='btn btn-success' onClick={markAsProfiled}><i class="bi bi-check"></i> Mark as Profiled</button> : <i class="bi bi-bookmark-check-fill"></i>} </div>
                      </div>
    
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Last Login</div>
                        <div className="col-lg-9 col-md-8">{userDetails.last_login_time}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Last Logout</div>
                        <div className="col-lg-9 col-md-8">{userDetails.last_logout_time}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Social Media Handles</div>
                        <div className="col-lg-9 col-md-8">{userDetails.socialMediaHandles.length > 0 ? userDetails.socialMediaHandles.map(handle =>{
                            return <p key={handle}>{handle}</p>
                        }):"none"}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Last Logout</div>
                        <div className="col-lg-9 col-md-8">{userDetails.last_logout_time}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Pending Payments</div>
                        <div className="col-lg-9 col-md-8">{pendingPaymentsLoading ?<h5>Loading...</h5>:pendingPayments.length > 0 ? 
                        <ul className='list-group'>
                            {pendingPayments.map(pmt =>{
                          return <li className='list-group-item' key={pmt.id}><strong>Amount:NGN</strong> {pmt.amount.toLocaleString("en-NG")} : <strong>Purpose:</strong> {pmt.purpose} <strong>Deadline :</strong> {new Date(pmt.expiryDate).toLocaleString("en-NG")} <strong>Code :</strong> {pmt.code.trim()} <Link to={`/expected/payment/update/${pmt.id}`} className='btn btn-warning btn-sm'><i className='bi bi-pencil-square'></i> Edit</Link> <button className='btn btn-danger btn-sm' onClick={()=>blockPendingPayment(pmt.id)}><i className='bi bi-lock-fill'></i> Close</button> <Link to={`/payment/record/${pmt.id}`} className="btn btn-success btn-sm"><i className='bi bi-check'></i> Record Receipt</Link></li>
                        })
                      }
                        </ul>:<h5>None</h5>}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label"></div>
                        <div className="col-lg-9 col-md-8"><NewExpectedPaymentModal /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
}
  </Layout>
}

export default UserDetails;
