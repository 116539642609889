import React,{useState, useEffect} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'
import 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-fixedheader-bs5'
import $ from 'jquery'; 

const BidList = () => {
    const links=[{url:'/', label: 'Dashboard'}]
    const [loading, setLoading]=useState(false)
    const [allBids, updateAllBids]=useState([])

    const controller=new AbortController()

    const getAllBids=()=>{
        setLoading(true)
        fetch(`${config.BASE_URL}bids`,httpOptions("GET"))
        .then(response =>{
            response.json()
            .then(res =>{
                updateAllBids(res)
                $(function () {
                    $('#list-table').DataTable({
                        fixedHeader: true,
                        buttons: ['copy', 'csv', 'excel']
                    });
                  })  
                setLoading(false)
            })
        })
        .catch(err=>{
            alert(err)
            setLoading(false)
        })
    }

    useEffect(()=>{
        getAllBids()
        return ()=>controller.abort()
    },[])
    
   const declareWinners=()=>{
       setLoading(true);
       fetch(`${config.BASE_URL}bids/declarewinners`,httpOptions("POST",{deadlinedate:null}))
       .then(async response=>{
           if(response.ok){
            const result=await response.json()
            alert(result.description)
            setLoading(false)
        }else{
            console.log(response)
            alert("System Error! Please retry") 
            setLoading(false)           
        }
       })
       .catch(err=>{
           alert(err)
       })
   }

return (
    <Layout title="All Bids" links={links}>
        <section className='align-items-center'>
            {
                loading ? <h5 className='text-center'>Loading...</h5> : allBids.length === 0 ? <h5 className='text-center'>No bids in record</h5>
                :
                <>
                <div className='my-2 d-flex justify-content-end'>
                    <button className='btn btn-success btn-lg my-2 justify-self-end' onClick={declareWinners}>Declare Winners</button>
                </div>
                <table className='table table-hover table-striped table-bordered' id='list-table'>
                <thead className='table-dark' id='list-table'>
                    <tr>
                        <th>Plot</th>
                        <th>Amount</th>
                        <th>Time of Bid</th>
                        <th>Bidder</th>
                        <th>Bid Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allBids.map(bid => {
                            return <tr key={bid.bidId}>
                                <td>{bid.propertyName}</td>
                                <td>{bid.bidAmount}</td>
                                <td>{bid.timeOfBid}</td>
                                <td>{bid.bidderName}</td>
                                <td>{bid.status}</td>
                            </tr>
                            // const dataInstance={"plotLabel":bid.propertyName,"bidAmount":bid.bidAmount,"timeOdBid":bid.timeOfBid,"bidderName":bid.bidderName,"bidStatus":bid.status}

                            // tableData.push(dataInstance);
                        })
                    }
                    </tbody>
                    </table>
                </>            
            }

        </section>
    </Layout>
  )
}

export default BidList