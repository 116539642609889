import {useState, useEffect} from 'react';
import Layout from '../shared/Layout';
import { useParams } from 'react-router-dom';
import config from '../../config.json'
import { httpOptions } from '../../repository';

const ExpectedPaymentUpdate = () => {
    const {id}=useParams()
    const [paymentDetails, updatePaymentDetails]=useState({})
    const [loading, setLoading]=useState(false)
    const [payData, setPayData]=useState({})
    const controller= new AbortController()
    const links=[{url:'/',label:'Dashboard'},{url:'/users',label:'Users'},{url:'/pending/payments',label:'All Expected Payments'}]

    const updatePayData=(e)=>{
        setPayData({...payData,[e.target.name]:e.target.value})
    }

    const updateRecord =()=>{
        if(window.confirm("Are you sure you want to update this expected payment?")){
            setLoading(true)

            const payloadData={
                "purpose": payData.purpose,
                "amount": payData.amountFigure,
                "deadline":payData.deadlineDate
            }

               // console.log(payloadData)
                const payloadBlob=new Blob([JSON.stringify(payloadData,null,2)],{type:"application/json"});

                setLoading(true)
                fetch(`${config.BASE_URL}payments/expected/update/${id}`, httpOptions("PATCH", payloadBlob))
                .then(async response =>{
                        const result=await response.json();
                        alert(result.description)
                        setLoading(false)
                        //window.location.href="/users/"+paymentDetails.payerId                   
                    }).catch(err=>{
                        alert("There was a system error. Please retry")
                        console.log(err)
                        setLoading(false)
                    })
           
        }
    }

    useEffect(()=>{

        const getPaymentDetails=()=>{
            setLoading(true)
            fetch(`${config.BASE_URL}payments/expected/${id}`,httpOptions('GET'))
            .then(response =>{
                if(response.ok){
               response.json().then(res=>{
                    updatePaymentDetails(res)
                    setPayData({amountFigure:res.amountFigure,deadlineDate:res.deadlineDate,purpose:res.purpose, deadlineString:res.deadlineString})
               })
                }else{
                    alert("System Error: Unable to get details of expected payment. Please retry")
                }
                setLoading(false)
            })
            .catch(err =>{
                alert(err)
                setLoading(false)
            })
        }

        getPaymentDetails()

        return ()=>controller.abort()
    },[id])


  return (
    <Layout title="Edit/Update Expected Payment" links={links}>
         <div className='row'>
                {
                    loading ? <h5>Loading...</h5> :
                    paymentDetails != null ? 
                    <>
                        <div className='col-md-4 offset-md-1'>
                            <dl className='row'>
                                <dt className='col-md-3'>Payer</dt>
                                <dd className='col-md-9'>{paymentDetails.payerName}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Expected Amount</dt>
                                <dd className='col-md-9'>{paymentDetails.amount}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Purpose</dt>
                                <dd className='col-md-9'>{paymentDetails.purpose}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Payment Code</dt>
                                <dd className='col-md-9'>{paymentDetails.paymentCode}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Current Status</dt>
                                <dd className='col-md-9'>{paymentDetails.status}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-md-3'>Deadline</dt>
                                <dd className='col-md-9'>{paymentDetails.deadlineString}</dd>
                            </dl>
                        </div>
                        <div className='col-md-4 offset-md-1'>
                            {
                                paymentDetails.status !=='Paid'?
                                <form>
                                    <div className='form-group mt-2'>
                                        <label className='control-label' htmlFor='purpose'>Purpose</label>
                                        <input type="text" name="purpose" id='purpose' className='form-control form-control-lg' onChange={updatePayData} value={payData.purpose} />
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label className='control-label' htmlFor='deadline'>Deadline </label>
                                        <input type="date" name="deadlineDate" className='form-control form-control-lg' onChange={updatePayData} id='deadline' value={payData.deadlineDate} />
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label className='control-label' htmlFor='amount'>Amount Paid in Chosen Currency {payData.amountFigure > 0 ? '(NGN '+ parseFloat(payData.amountFigure).toLocaleString("en-NG")+')' :''}</label>
                                        <input type="number" min='0' name="amountFigure" className='form-control form-control-lg' onChange={updatePayData} value={payData.amountFigure} id='amount' />
                                    </div>
                                   
                                    <div className='form-group mt-2 text-end'>
                                        <button className='btn btn-success btn-lg' onClick={updateRecord} type="button"><i className='bi bi-check'></i> Update</button>
                                    </div>
                                </form>:''
                            }
                        </div> 
                    </> : <h5>No record for this payment</h5>
                }            
            </div>
    </Layout>
  )
}

export default ExpectedPaymentUpdate