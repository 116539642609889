import React, {useState, useEffect} from 'react'
import Layout from '../shared/Layout'
import { Link } from 'react-router-dom'
import config from '../../config.json'
import { httpOptions } from '../../repository'
import 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-fixedheader-bs5'
import $ from 'jquery'; 


const PaymentListPending = () => {
    const links=[{url:'/', label: 'Dashboard'}]
    const [paymentsData, updatePaymentData]=useState([])
    const [loading, setLoading]=useState(false)
    const controller =new AbortController()
    const tableLabels=[
        { text: "Expected Payer", value: "expectedPayer" },
        { text: "Amount", value: "amount" },
        { text: "Purpose", value: "purpose" },
        { text: "Payment Code", value: "paymentCode" },
        { text: "Action", value: "action" },
      ];

      let tableData=[];

    const getPaymentRecords=()=>{
        setLoading(true)
        fetch(config.BASE_URL+'payments/pending', httpOptions('GET'))
        .then(response =>{
            if(response.ok){
            response.json()
            .then(res=>{
                updatePaymentData(res)
                $(function () {
                    $('#list-table').DataTable({
                        fixedHeader: true,
                        buttons: ['copy', 'csv', 'excel']
                    });
                  })  
                setLoading(false)
            })
        }else{
            alert("There was a system error. Please retry")
            setLoading(false)
        }
        })
        .catch(err =>{
            alert(err)
            setLoading(false)
        })
    }

    useEffect(()=>{
        getPaymentRecords()
        return ()=>controller.abort()
    },[])


  return (
    <Layout title="Pending Payments" links={links}>
        <section>
            <div className='card'>
                <div className='card-body'>
                    {
                        loading ? <h5>Loading...</h5> :
                        paymentsData.length === 0 ? <h5 className=''> No payment records found</h5>:   
                        <table className='table table-hover table-striped table-bordered' id='list-table'>
                            <thead className='table-dark' id='list-table'>
                                <tr>
                                    <th>Payer Name</th>
                                    <th>Amount</th>
                                    <th>Purpose</th>
                                    <th>Payment Code</th>
                                    <th>Deadline Date</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>                          
                                {
                                paymentsData.map(pmt => {
                                    return <tr key={pmt.paymentId}>
                                            <td><Link to={`/users/${pmt.payerId}`}>{pmt.payerName}</Link></td>
                                            <td>{pmt.amount}</td>
                                            <td>{pmt.purpose}</td>
                                            <td>{pmt.paymentCode}</td>
                                            <td>{pmt.deadlineDate}</td>
                                            <td><Link to={`/payment/record/${pmt.paymentId}`}><i className='bi bi-pen'></i> Record Payment Receipt</Link></td>
                                        </tr>
                                })}
                                </tbody>
                                </table>
                            }
                    </div>
                </div>
               
        </section>    
    </Layout>
  )
}

export default PaymentListPending