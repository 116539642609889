import React, {useState, useEffect} from 'react'
import Dashboard from '../Dashboard'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'
import 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-fixedheader-bs5'
import $ from 'jquery'; 

const PaymentListSuccessfull = () => {
    const links=[{url:'/', label: 'Dashboard'}]
    const [paymentsData, updatePaymentData]=useState([])
    const [loading, setLoading]=useState(false)
    const controller =new AbortController()


    const getPaymentRecords=()=>{
        setLoading(true)
        fetch(config.BASE_URL+'payments', httpOptions('GET'))
        .then(response =>{
            if(response.ok){
            response.json()
            .then(res=>{
                updatePaymentData(res)
                $(function () {
                    $('#list-table').DataTable({
                        fixedHeader: true,
                        buttons: ['copy', 'csv', 'excel']
                    });
                  })  
                setLoading(false)
            })
        }else{
            console.log(response)
            alert("There was a system error. Please retry")
            setLoading(false)
        }
        })
        .catch(err =>{
            alert(err)
        })
    }

    useEffect(()=>{
        getPaymentRecords()
        return ()=>controller.abort()
    },[])

  return (
    <Layout title="Successful Payments" links={links} >
        <section>
            <div className='card'>
                <div className='card-body'>
                    {
                        loading ? <h5>Loading...</h5> :
                        paymentsData.length === 0 ? <h5 className=''> No payment records found</h5>:
                        <table className='table table-striped table-hover table-bordered' id="list-table">
                            <thead className='table-dark'>
                                <tr>
                                    <th>Payer Name</th>
                                    <th>Amount</th>
                                    <th>Time of Payment</th>
                                    <th>Purpose</th>
                                    <th>Payment Code</th>
                                    <th>Transaction Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentsData.map(pmt => {
                                    return <tr key={pmt.paymentId}>
                                        <td>{pmt.payerName}</td>
                                        <td>{pmt.amount}</td>
                                        <td>{pmt.timeCompleted}</td>
                                        <td>{pmt.purpose}</td>
                                        <td>{pmt.paymentCode}</td>
                                        <td>{pmt.transactionCode}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default PaymentListSuccessfull