import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../shared/Layout'
import { httpOptions } from '../../repository'
import config from '../../config.json'

const UserBids = () => {
    const {id}=useParams()
    const [loading, setLoading]=useState(false)
    const [bids, updateBids]=useState([])
    const controller= new AbortController()
    const links=[{url:'/', label:'Dashboard'},{url:'/users/',label:"User List"},{url:'/users/'+id,label:"User Details"}]

    const getUserBids=()=>{
        setLoading(true)
        fetch(config.BASE_URL+'users/bids/'+id, httpOptions('GET'))
        .then(response =>{
            response.json()
            .then(res =>{
                // console.log(res)
                if(res.status){                   
                        updateBids(res.bids)
                    }else{
                        alert(res.description)
                    }
                    setLoading(false)
                    // console.log(bids)
                })
        })
        .catch(err=>{
            console.log(err)
            alert(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        getUserBids()        
        return () => controller.abort()
    }, [id])
    
  return (
    <Layout title="User Bids" links={links}>
        <section className="section profile">
            <div className="row">
                <div className="col-md-6 offset-md-3">
        
                    <div className="card">
                        <div className='card-body'>
                            {
                            loading ? <h5 class='text-center'>Loading...</h5> :
                           
                                <div className="tab-content pt-2">
    
    <div className="fade show active">
         <h5 className="card-title">Bids Found in Record</h5>
         {bids.map(bid=>
            <div className='card' key={bid.bidId}>
                <div className='card-body'>
                    <div className='row pt-sm-3'>
                        <div className="col-4 label">Property</div>
                        <div className="col-8">{bid.propertyName}
                    </div>
                    <hr className='mt-2' />

                    <div className="col-4 label ">Amount</div>
                        <div className="col-8">{bid.bidAmount}
                    </div>
                    <hr className='mt-2' />

                    <div className="col-4 label ">Time of Bid</div>
                        <div className="col-8">{bid.timeOfBid}
                    </div>
                    <hr className='mt-2' />

                    <div className="col-4 label ">Status of Bid</div>
                        <div className="col-8">{bid.status}
                    </div>
                    <hr className='mt-2' />

                </div>
                </div>
            </div>
         )}

    </div>
  </div>
                           
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default UserBids