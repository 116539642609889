import React from 'react'
import {Link} from 'react-router-dom'
import Layout from './Layout'
const PageNotFound = () => {
    const urls=[{"url":"","label":"Dashboard"}]
    return (
        <Layout title="Page Not Found" links={urls} >
             <div className="col-10 offset-1 col-md-4 offset-md-4">
                <div className='card mb-sm-3 mb-md-5'>
                    <div className='card-header'>
                        Page Not Found
                    </div>
                    <div className='card-body'>
                        <p className='lead mb-3'>Sorry, the page you are looking for is not available. Please click the link below to go back to home page</p>
                        <p className='text-center mt-4'>
                        <Link to='/'> <span className='submit-btn'>Home</span> </Link> 
                        </p>                     
                    </div>
                </div>
            </div>
        </Layout>
   
   
    )
}

export default PageNotFound
