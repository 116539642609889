import React from 'react'
import {Link} from 'react-router-dom'

const Breadcrumbs = (props) => {
   return (
        <div className="pagetitle">
            <h1>{props.title}</h1>
            <nav>
                <ol className="breadcrumb">
                {/* <li className="breadcrumb-item"><Link to="/">Home</Link></li> */}
                {props.links != null && props.links.length > 0 ?props.links.map(link=>{
                    return <li 
                    className="breadcrumb-item" 
                    key={link.url}><Link 
                        to={link.url}>{link.label}</Link>
                        </li>
                }):''}
                </ol>
            </nav>
        </div>
    )
}

export default Breadcrumbs
