import {useState,useEffect} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { Link } from 'react-router-dom'
import { httpOptions } from '../../repository'

const MessageList = () => {
    const [loading, setLoading]=useState(false)
    const [allMessages,setMessages]=useState([])
    const controller= new AbortController()
    useEffect(()=>{
        const getAllMessages=()=>{
            setLoading(true)
            fetch(`${config.BASE_URL}guestmessages`, httpOptions("GET"))
            .then(async response=>{
                const result=await response.json()
                setMessages(result)
                setLoading(false)
            })
            .catch(err=>{
                alert(err)
                setLoading(false)
            })
        }
    
        getAllMessages();
        return ()=>controller.abort()
    },[])
  return (
    <Layout title="Messages from Website">

     <section>
            <div className='row'>
                <div className='col-md-10 offset-md-1'>
                    <div className='card'>
                        <div className='card-body d-flex align-items-center justify-content-center'>
                            {
                                loading ? <h5>Loading...</h5> :
                                allMessages.length === 0 ? <h5 className=''> No messages found</h5>:
                                <table className='table table-striped table-hover table-bordered' id="list-table">
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Sender Email</th>
                                            <th>Subject</th>
                                            <th>Time Sent</th>
                                            <th>Status</th>
                                            <th>View</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allMessages.map(msg => {
                                            return <tr key={msg.messageId}>
                                                <td>{msg.senderEmail}</td>
                                                <td>{msg.messageSubject}</td>
                                                <td>{msg.timeSent}</td>
                                                <td>{msg.readStatus}</td>
                                                <td><Link to={`/messages/${msg.messageId}`}><i className='bi bi-eye-fill'></i> View</Link></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>    
        
    </Layout>
  )
}

export default MessageList