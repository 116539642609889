import React from 'react'

const Footer = () => {
    return (
        <footer id="footer" className="footer">
    <div className="copyright">
      &copy; 2022 <strong><span>ePABS</span></strong>. All Rights Reserved
    </div>
   
  </footer>
    )
}

export default Footer
