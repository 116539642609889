import {useEffect, useState} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { useParams } from 'react-router-dom'
import { httpOptions } from '../../repository'

const MessageDetails = () => {
    const links=[{url:'/dashboard', label:'Dashboard'},{url:'/messages', label:"All Messages"}]

    const [loading, setLoading]=useState(false)
    const [msgDetails,setMsgDetails]=useState({senderName:'null',})
    const abortController= new AbortController()
    const {id}=useParams()


    useEffect(async ()=>{
      const getMessageDetails=()=>{
        setLoading(true)
        fetch(`${config.BASE_URL}guestmessages/${id}`, httpOptions("GET"))
        .then(async response =>{
          if(response.ok){
            const msgInfo=await response.json();
              setMsgDetails(msgInfo);
            }else{
              console.log(response)
              alert("System Error! Please retry")
            }
            setLoading(false)
        })
        .catch(err=>{
          alert(err)
          setLoading(false)
        })
        setLoading(false)
      }
      getMessageDetails()
      return ()=>abortController.abort();
    },[id])
  return (
    <Layout title="Message Details" links={links}>
      
        <section className="section profile">
        <div className="row">
          <div className="col-xl-4">

            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-start">
                <h2 className="my-2">Message Subject: {msgDetails.messageSubject}</h2>
                <hr style={{border:"1px dashed gold", width:"100%"}}/>
                <h3 className="my-2">Sender's Email: {msgDetails.senderEmail}</h3>                
                <hr style={{border:"1px dashed gold", width:"100%"}}/>
                <h3 className="my-2">Sender's Phone: {msgDetails.senderPhone}</h3>  
                <hr style={{border:"1px dashed gold", width:"100%"}}/>
                <h3 className="my-2">Time Sent: {msgDetails.timeSent}</h3> 
                <hr style={{border:"1px dashed gold", width:"100%"}}/>
                <h3 className="my-2">Time Read: {msgDetails.readStatus}</h3>               
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="card">
              <div class="card-body pt-3">                
                <div class="tab-content pt-2"> 
                <h3 className='text-center'>Message Content</h3>  
                <hr style={{border:"1px dashed gold", width:"100%"}}/>
                    <p>{msgDetails.messageContent}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MessageDetails