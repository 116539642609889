import {logout} from '../../repository'
import { NavLink, Link } from 'react-router-dom'

const Topbar = () => {
    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between">
            <NavLink to={'/'} className="logo d-flex align-items-center">
            <img src="/assets/images/icons/android-icon-48x48.png" alt="epabs icon" />
            <span className="d-none d-lg-block">ePABS Admin</span>
            </NavLink>
            <i class="bi bi-list toggle-sidebar-btn" id="toggle-sidebar-btn"></i>
        </div>
        
        <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">

                <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                <img src="/assets/images/icons/default-profile-icon2.jpg" alt="Profile" className="rounded-circle" />
                <span className="d-none d-md-block dropdown-toggle ps-2">{localStorage.getItem('admin') || 'Unknown'}</span>
                </a>
                {/* <!-- End Profile Iamge Icon --> */}

                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                    <h6>{localStorage.getItem('admin') || 'Unknown'}</h6>
                    <span>{localStorage.getItem('role') || ''}</span>
                </li>
                <li>
                    <hr className="dropdown-divider" />
                </li>

                <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/my/profile">
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                    </Link>
                </li>
                <li>
                    <hr className="dropdown-divider" />
                </li>            
                <li>
                    <hr className="dropdown-divider" />
                </li>

                <li onClick={logout}>
                    <Link className="dropdown-item d-flex align-items-center" to="#">
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                    </Link>
                </li>
                </ul>
            </li>
            </ul>
        </nav>

        </header>
    )
}

export default Topbar
