import {useState, useEffect} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'

const GrantEmailAuth = () => {
    const links=[{url:'/', label:"Dashboard"},{url:'/admin/authorizedemails',label:"Authorized Admin Emails"},{url:'/admins', label:"Admin Users"}]

    const [authInfo, updateAuthInfo]=useState({emailAddress:'',roleName:''})
    const [loading,setLoading]=useState(false)

    const submitAdminAuthForm=(e)=>{
      e.preventDefault()
      setLoading(true)
      const tokenBlob= new Blob([JSON.stringify(authInfo,null,2)],{type:"application/json"})
      fetch(`${config.BASE_URL}adminusers/auth/addnew`,httpOptions("POST",tokenBlob))
      .then(async response =>{
        if(response.ok){
          const result = await response.json()
          alert("Admin registration authorization successfully added")
          window.location.href='/admin/authorizedemails'
        }else{
          console.log(response)
          alert("System Error! Please retry")
          setLoading(false)
        }
      })
      .catch(err=>{
        alert(err)
        setLoading(false)
      })
    }

    const buildAuthInfo=(event)=>{
      updateAuthInfo({...authInfo,[event.target.name]:event.target.value})
    }

  return (
    <Layout title="Add Authorized Admin Email Address" links={links}>
      <section>
        <div className='col-md-6 col-sm-8 offset-md-3 offset-sm-2'>
        <div class="card mb-3">

<div class="card-body">

  <div class="pt-4 pb-2">
    <h5 class="card-title text-center pb-0 fs-4">Add Authorized Admin Email</h5>
    <p class="text-center small">Enter the email and role of the desired admin user</p>
  </div>

  <form class="row g-3 needs-validation" onSubmit={submitAdminAuthForm}>
    <div class="form-group">
      <label for="adminEmail" class="form-label">Admin Email</label>
      <div className='input-group'>
      <span class="input-group-text" id="inputGroupPrepend">@</span>
      <input type="email" name="emailAddress" value={authInfo.emailAddress} onChange={buildAuthInfo} class="form-control" id="adminEmail" required />
      </div>    
    </div>

    <div class="form-group">
      <label for="adminRole" class="form-label">Admin Role Level</label>
      <div>
        <select value={authInfo.roleName} onChange={buildAuthInfo} name="roleName" class="form-control" id="adminRole" required>
        <option value=''>--Select--</option>
          <option value='admin'>Admin</option>
          <option value='superAdmin'>Super Admin</option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-primary w-100" type="submit">Add Admin Authorization</button>
    </div>
    
  </form>

</div>
</div>

        </div>


      </section>
    </Layout>
  )
}

export default GrantEmailAuth