import React, {useState, useEffect} from 'react'
import Layout from '../shared/Layout'
import config from '../../config.json'
import { httpOptions } from '../../repository'

const PaymentListFailed = () => {
    const links=[{url:'/', label: 'Dashboard'}]
    const [paymentsData, updatePaymentData]=useState([])
    const [loading, setLoading]=useState(false)
    const controller =new AbortController()

    const getPaymentRecords=()=>{
        setLoading(true)
        fetch(config.BASE_URL+'payments/failed', httpOptions('GET'))
        .then(response =>{
            if(response.ok){
            response.json()
            .then(res=>{
                updatePaymentData(res)
                setLoading(false)
            })
        }else{
            console(response)
            alert("There was a system error. Please retry")
            setLoading(false)
        }
        })
        .catch(err =>{
            alert(err)
        })
    }

    useEffect(()=>{
        getPaymentRecords()
        return ()=>controller.abort()
    },[])


  return (
    <Layout title="Failed Payments" links={links}>
                <section>
            <div className='row'>
                <div className='col-md-10 offset-md-1'>
                    <div className='card'>
                        <div className='card-body d-flex align-items-center justify-content-center'>
                            {
                                loading ? <h5>Loading...</h5> :
                                paymentsData.length === 0 ? <h5 className=''> No payment records found</h5>:
                                <table className='table table-striped table-hover table-bordered' id='list-table'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Payer Name</th>
                                            <th>Amount</th>
                                            <th>Time of Payment</th>
                                            <th>Purpose</th>
                                            <th>Payment Code</th>
                                            <th>Transaction Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentsData.map(pmt => {
                                            return <tr key={pmt.paymentId}>
                                                <td>{pmt.payerName}</td>
                                                <td>{pmt.amount}</td>
                                                <td>{pmt.timeCompleted}</td>
                                                <td>{pmt.purpose}</td>
                                                <td>{pmt.paymentCode}</td>
                                                <td>{pmt.transactionCode}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    </Layout>
  )
}

export default PaymentListFailed